import { Col, Row } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import React from 'react'

type FixedSizeArray<N extends number, T> = N extends 0
	? never[]
	: {
			0: T
			length: N
	  } & ReadonlyArray<T>

type SupportOptions = 'irlaToolkits' | 'schoolPace' | 'arcReads'

interface Props {
	options: FixedSizeArray<3, SupportOptions>
}

const supports = {
	irlaToolkits: { name: 'IRLA Toolkits', symbol: '', description: 'Differentiated, targeted, and efficient skills instruction', image: 'irla-toolkits' },
	schoolPace: { name: 'SchoolPace', symbol: '©', description: 'Real-time student data performance management system', image: 'schoolpace' },
	arcReads: { name: 'ARC Reads', symbol: '®', description: 'Digital logging solution for students and teachers', image: 'arc-reads' }
}

function matchSupports(options: Props['options']) {
	return options.map((o) => supports[o])
}

export default function AdditionalSupports({ options }: Props) {
	return (
		<Row>
			<Col>
				<div className="relative">
					<div className="arc-gradient rounded-t p-3 text-center">
						<span className="text-3xl font-bold text-white">Additional Supports</span>
					</div>
					<div className="arc-gradient-light relative w-full px-6 py-5">
						<div className="flex flex-wrap gap-x-20 gap-y-8 sm:flex-nowrap">
							{matchSupports(options).map((support) => {
								return (
									<div className="w-full sm:w-1/3">
										<img src={`/images/additional-supports/${support.image}.png`} alt={support.name} className="mx-auto mb-6 max-h-48" />
										<span className="mt-3 mb-1 inline-block w-full text-center text-3xl font-medium">
											{support.name}
											<sup>{support.symbol}</sup>
										</span>
										<P className="text-center">{support.description}</P>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</Col>
		</Row>
	)
}
