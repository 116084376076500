import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import SectionTitle from 'components/global/SectionTitle'
import ReviewsResults from 'components/state-reviews/ReviewsResults'
import LargeForm from 'components/forms/LargeForm'
import AdditionalSupports from 'components/global/AdditionalSupports'
import { LanguageToggle } from '@arckit/ui'
import Resource from 'components/global/Resource'
import OverlayVideo from 'components/overlay-video'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import Modules from 'components/100-book-challenge/Modules'

export default function HundredBookChallengePage() {
	const [language, setLanguage] = useState<'english' | 'spanish'>('english')

	return (
		<Layout pageName="100 Book Challenge">
			<Section>
				<div className="absolute top-0 left-0 z-0 h-full w-full bg-cover bg-no-repeat opacity-90" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
					<div className="white-transparent-gradient-strong h-full w-full"></div>
				</div>
				<Row center className="relative z-auto pt-10 sm:pt-20">
					<Col width="w-full sm:w-1/2">
						<div>
							<StaticImage src="../../static/images/logos/100-bc-logo-halo.png" loading="eager" alt="arc core logo" />
						</div>
					</Col>
				</Row>
				<Row center className="relative z-auto">
					<Col width="w-full" className="text-center">
						<H1 className="mx-auto mb-3 max-w-3xl">Structured Independent Reading System for Home and School</H1>
					</Col>
				</Row>
			</Section>
			<Section>
				<Row>
					<Col className="mb-10">
						<LanguageToggle language={language} onClickEnglish={() => setLanguage('english')} onClickSpanish={() => setLanguage('spanish')} />
					</Col>
				</Row>
			</Section>
			<Section>
				<SectionTitle
					title="Strengthens Any Core Program"
					text="The 100 Book Challenge accelerates learning in any core program by adding daily opportunities to transfer taught skills to high-interest texts."
				/>
				<Row>
					<Col className="text-center">
						<img src={`/images/100-book-challenge/accelerate-learning-${language}.png`} alt="meaningful practice" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle
					title="Enhances Literacy Expertise for Every Teacher"
					text="Teacher-friendly tools provide real-time, actionable data about what students know and can do, and identify the sequence of skills and lessons they need to learn next to accelerate growth."
				/>
				<Row middle>
					<Col>
						<img src={`/images/100-book-challenge/literacy-expertise-${language}.png`} alt="literacy expertise" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle
					title="Provides Structured Practice for Every Student"
					text="Rotating Classroom Libraries ensure students have access to a steady stream of new and exciting books in every classroom."
				/>
				<Row>
					<Col className="text-center">
						<img src={`/images/100-book-challenge/rotating-classroom-libraries-${language}.png`} alt="rotating classroom libraries" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 sm:mt-40">
				<Row className="items-center">
					<Col width="w-full md:w-5/12" className="">
						<div className="-mr-0 md:-mr-48">
							<OverlayVideo title="Inspiring a Passion for Reading" />
						</div>
					</Col>
					<Col width="w-full md:w-7/12" className="bg-white py-0 pl-4 sm:bg-slate-50 md:py-40 md:pl-56">
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Watch It in Action</span>
						<P size="md">Learn how 100 Book Challenge can transform a school's reading culture and help educators advance student literacy.</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle
					title="Engages and Empowers Families"
					text={[
						<span key="sectionTitleSpanTag1">
							Family members are partners in ongoing education through family-friendly skills cards and the IRLA<sup>®</sup> Home Update, which provides real-time
							feedback on the student’s reading skills.
						</span>
					]}
				/>
				<Row>
					<Col className="text-center">
						<div className="mb-20">
							<img src={`/images/100-book-challenge/empower-families-${language}.png`} alt="trade quality texts" />
						</div>
					</Col>
				</Row>
			</Section>

			<Section margin="mt-10" className="bg-ab-100">
				<Row center className="relative z-auto">
					<div className="mt-14 max-w-3xl px-4">
						<div className="relative">
							<svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-10 -left-20 z-10 hidden h-20 md:block" viewBox="0 0 130.51 115">
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
							<p className="relative z-20 text-center text-2xl italic text-white">
								School-aged children are expected to learn an estimated 3,000 to 4,000 words each year (Nagy and Herman, 1987; D’Anna, Zechmeister, and Hall, 1991).
								Learning this volume of words in such a short period requires some amount of direct teaching but is largely dependent upon incidental learning
								through independent wide reading and other activities that immerse students in language.
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="absolute -bottom-10 -right-28 z-10 hidden h-20 rotate-180 transform md:block lg:bottom-0"
								viewBox="0 0 130.51 115"
							>
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
						</div>
						<div className="mx-auto my-6 w-32 border-b-4 border-b-ab-100"></div>
						<p className="text-center text-white">–Cobb & Blachowicz, 2014</p>
					</div>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle
					title="What You Get"
					text={[
						<span key="sectionTitlePTag1">
							Choose your grade below to view what is included across the year.
							<br></br>
							<span className="text-xs">All title and basket images are representative samples; actual titles may vary.</span>
						</span>
					]}
				/>
				<Modules language={language} />
			</Section>

			<ReviewsResults />
			<Section>
				<Row center>
					<Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="An Evaluation of the 100 Book Challenge Program (William Penn)" />
						<div className="mt-4 text-center">
							<span className="font-semibold">An Evaluation of the 100 Book Challenge Program</span>
						</div>
					</Col>
					<Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="An Evaluation of the 100 Book Challenge Program" />
						<div className="mt-4 text-center">
							<span className="font-semibold">An Evaluation of the 100 Book Challenge Program</span>
						</div>
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<AdditionalSupports options={['irlaToolkits', 'schoolPace', 'arcReads']} />
			</Section>
			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="100 Book Challenge" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
